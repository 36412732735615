export const fetchClassesCart = async () => {
  try {
    const response = await fetch(`/api/account/personalize`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return response.json().cartCount
  } catch (error) {
    console.log(error)
    return 0
  }
}

export const fetchShopifyCalmCart = async (baseUrl, token) => {
  try {
    const response = await fetch(`${baseUrl}/cart/shopify-calm-cart/${token}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return response.json()
  } catch (error) {
    console.log(error)
    return null
  }
}

export const fetchShopifyCart = async (baseUrl, token) => {
  try {
    const response = await fetch(`${baseUrl}/cart/shopify-cart/${token}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return response.json()
  } catch (error) {
    console.log(error)
    return null
  }
}
