import React, { useState, useCallback, useMemo, createContext, useContext } from "react"
import Cookies from "js-cookie"

import { fetchClassesCart, fetchShopifyCart, fetchShopifyCalmCart } from "utils/fetchCart"

const CartContext = createContext(null)

export const CartProvider = ({ ...props }) => {
  const baseUrl = process.env.GATSBY_LOCAL_SITE

  const [cart, setCart] = useState({
    numCartItems: 0,
    numCartClassesItems: 0,
    numCartProductItems: 0,
    numCartCalmProductItems: 0,
  })
  const [shopifyCartToken, setShopifyCartToken] = useState(Cookies.get("shopmbg_cart_token"))
  const [shopifyCalmCartToken, setShopifyCalmCartToken] = useState(Cookies.get("calm_cart_token"))

  const getCart = useCallback(async () => {
    let { numCartClassesItems, numCartProductItems, numCartCalmProductItems } = cart

    try {
      const cartCount = await fetchClassesCart()
      numCartClassesItems = cartCount
    } catch (error) {
      console.log("fetchClassesCart error:", error)
    }

    if (shopifyCartToken) {
      try {
        const shopifyCart = await fetchShopifyCart(baseUrl, shopifyCartToken)
        numCartProductItems = shopifyCart.item_count
      } catch (error) {
        console.log("fetchShopifyCart error:", error)
      }
    }

    if (shopifyCalmCartToken) {
      try {
        const shopifyCalmCart = await fetchShopifyCalmCart(baseUrl, shopifyCalmCartToken)
        numCartCalmProductItems = shopifyCalmCart.item_count
      } catch (error) {
        console.log("fetchShopifyCalmCart error:", error)
      }
    }

    setCart({
      numCartItems: numCartClassesItems + numCartProductItems + numCartCalmProductItems,
      numCartClassesItems,
      numCartProductItems,
      numCartCalmProductItems,
    })
  }, [baseUrl, shopifyCartToken, shopifyCalmCartToken])

  const value = useMemo(
    () => ({
      ...cart,
      getCart,
      shopifyCartToken,
      setShopifyCartToken,
      shopifyCalmCartToken,
      setShopifyCalmCartToken,
    }),
    [cart, getCart, shopifyCartToken, shopifyCalmCartToken],
  )

  return <CartContext.Provider value={value} {...props} />
}

export const useCart = () => {
  const context = useContext(CartContext)
  if (!context) {
    throw new Error(`useCart must be used within a CartProvider`)
  }
  return context
}
