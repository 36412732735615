import React, { useState, useCallback, useMemo, createContext, useContext } from "react"
import { useUser } from "./user"

const MainCartContext = createContext({})

export const getCart = async ({ email = null, promoCode = null, removePromo = null }) => {
  const params = new URLSearchParams()

  if (email) {
    params.append("email", email)
  }
  if (promoCode) {
    params.append("promoCode", promoCode)
  }
  if (removePromo) {
    params.append("removePromo", removePromo)
  }

  try {
    const response = await fetch(`/api/checkout/get-cart?${params}`)
    const data = await response.json()
    return data
  } catch (error) {
    return error
  }
}

export const addToMainCart = async (itemId) => {
  const params = new URLSearchParams()
  if (itemId) {
    params.append("itemId", itemId)
  }

  try {
    const response = await fetch(`/api/checkout/add-to-cart?${params}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
    return await response.json()
  } catch (error) {
    return error
  }
}

export const removeFromCart = async (itemId) => {
  const params = new URLSearchParams()
  if (itemId) {
    params.append("id", itemId)
  }
  // returns new object of cart
  try {
    const response = await fetch(`/api/checkout/remove-from-cart?${params}`, {
      method: "DELETE",
    })
    return await response.json()
  } catch (error) {
    return error
  }
}

export const applyPromoCode = async (promoCode) => {
  const params = new URLSearchParams()
  if (promoCode) {
    params.append("promoCode", promoCode)
  }
  try {
    const response = await fetch(`/api/checkout/apply-promo-code?${params}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
    return await response.json()
  } catch (error) {
    throw error.response
  }
}

export const generateBraintreeToken = async () => {
  try {
    const response = await fetch("/api/checkout/generate-token")
    const data = await response.json()
    return data.clientToken
  } catch (error) {
    return error
  }
}

export const processTransaction = async (body) => {
  try {
    // const response = await axios.put("/cart/gift", body)
    const response = await fetch("/api/checkout/process-transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
    if (response.status !== 200) {
      const error = await response.json()
      if(error.userAlreadyOwnsAllClasses) {
        return {
          userAlreadyOwnsAllClasses: true
        }       
      }
      throw new Error(error.error || error.message || "There was an error processing your payment")
    }
    const data = await response.json()
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export const createTransaction = async (merchant) => {
  try {
    const response = await fetch("/api/checkout/create-transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(merchant),
    })
    return response.json()
  } catch (error) {
    return error
  }
}

export const getOrderDetailsByOrderId = async (orderId) => {
  const params = new URLSearchParams()
  if (orderId) {
    params.append("orderId", orderId)
  }

  try {
    const response = await fetch(`/api/checkout/get-order?${params}`)
    return response
  } catch (error) {
    return error
  }
}

export const MainCartProvider = ({ ...props }) => {
  const [cart, setCart] = useState({})
  const [cartError, setCartError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [order, setOrder] = useState(null)
  const {
    user: { email },
  } = useUser()

  const fetchCart = useCallback(
    async (promoCode, removePromo) => {
      try {
        setIsLoading(true)
        const response = await getCart({ email, promoCode, removePromo })
        if (response.items.length === 0) window.location.href = "/classes"
        setCart(response)
        return response
      } catch (err) {
        setCartError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [email],
  )

  const removeItemFromCart = useCallback(
    async (id) => {
      setIsLoading(true)
      try {
        await removeFromCart(id)
        fetchCart()
      } catch (err) {
        setCartError(err)
      }
    },
    [fetchCart],
  )

  const handleApplyPromoCode = useCallback(
    async (promoCode) => {
      setIsLoading(true)
      try {
        const response = await fetchCart(promoCode)
        if (response.promoCode) {
          return response.promoCode
        }
        return null
      } catch (err) {
        setCartError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [fetchCart],
  )

  const removePromoCode = useCallback(async () => {
    setIsLoading(true)
    try {
      await fetchCart(null, true)
    } catch (err) {
      setCartError(err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const value = useMemo(
    () => ({
      cart,
      setCart,
      cartError,
      isLoading,
      setIsLoading,
      setCartError,
      fetchCart,
      removeItemFromCart,
      generateBraintreeToken,
      handleApplyPromoCode,
      removePromoCode,
      addToMainCart,
      order,
      setOrder,
      getOrderDetailsByOrderId,
    }),
    [
      cart,
      cartError,
      isLoading,
      fetchCart,
      removeItemFromCart,
      handleApplyPromoCode,
      removePromoCode,
      order,
    ],
  )

  return <MainCartContext.Provider value={value} {...props} />
}

export const useMainCart = () => {
  const context = useContext(MainCartContext)
  if (!context) {
    throw new Error(`useCart must be used within a MainCartProvider`)
  }
  return context
}
